import Joi from 'joi-browser';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from '../../common/Form';
import FormHeader from '../../common/FormHeader';
import userService from '../../../services/userService';
import ResetRequested from './components/ResetRequested';
import auth from '../../../services/authService';
import { setDocTitle } from '../../../utils/utilities';

class ResetPassword extends Form {
  state = { data: { email: '' }, errors: {}, submitted: false };

  schema = {
    email: Joi.string().email().required().label('Email'),
  };

  componentDidMount() {
    setDocTitle('Reset Password');
  }

  doSubmit = async () => {
    try {
      await userService.resetPassword(this.state.data.email);
      this.setState({ submitted: true });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.setState({
          errors: { email: 'There is no account associated with this email.' },
        });
      } else if (ex.response && ex.response.status === 500) {
        toast.error('An unexpected error occurred. Please try again later.');
      }

      this.setState({ submitted: false });
    }
  };

  render() {
    // If there is an existing user, redirect to home
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    const { submitted } = this.state;
    const { email } = this.state.data;

    switch (submitted) {
      case true:
        return <ResetRequested email={email} />;
      default:
        return (
          <div className="vertical-center">
            <form onSubmit={this.handleSubmit} className="form-reset">
              <FormHeader title="Enter your email and we will send instructions to reset your password." />
              {this.renderInput('email', 'Email Address', 'email')}
              {this.renderButton('Reset Password')}
              <div className="text-center">
                <span>Or </span>
                <Link to="/signin">Sign In</Link>
              </div>
            </form>
          </div>
        );
    }
  }
}

export default ResetPassword;
