import FormHeader from '../../../../common/FormHeader';
import { Link } from 'react-router-dom';
import BackButton from '../BackButton';

const TeamOptions = ({ firstName, onShowJoinTeam, onShowCreateTeam, onGoBack }) => {
  return (
    <div className="form-signup">
      <BackButton handler={onGoBack} />
      <FormHeader title={getTitle(firstName)} />
      <div className="no-new-teams">
        ⚠️ Migrupo is currently not accepting new teams. We apologize for any inconvenience this may cause.
      </div>
      <button onClick={onShowJoinTeam} className="btn btn-primary btn-block mb-3">
        Join a Team
      </button>
      <button onClick={onShowCreateTeam} className="btn btn-primary btn-block mb-3" disabled>
        Create a Team
      </button>

      <div className="text-center">
        <span>Not sure? </span>
        <Link to="/faq#join-create-team">Learn more</Link>
      </div>
    </div>
  );
};

function getTitle(firstName) {
  return `What would you like to do ${firstName}?`;
}

export default TeamOptions;
