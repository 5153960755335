const ServiceDate = ({ item }) => {
  return (
    <div className="service-date">
      <span className="service-date__month">{getMonthName(item.date)} </span>
      <span className="service-date__day">{getDayNumber(item.date)}</span>
    </div>
  );
};

function getMonthName(date) {
  const d = new Date(date);
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return month[d.getMonth()].toUpperCase();
}

function getDayNumber(date) {
  const d = new Date(date);
  return d.getDate();
}

export default ServiceDate;
