import FormHeader from '../../../../common/FormHeader';
import { Link } from 'react-router-dom';

const ResetRequested = ({ email }) => {
  return (
    <div className="vertical-center">
      <div className="form-reset">
        <FormHeader
          title={`Check your email inbox or spam folder. 
We have sent an email to ${email} with instructions to reset your password.`}
        />
        <Link to="/signin" className="btn btn-primary btn-block mb-3">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default ResetRequested;
