function Icon({ name, className, ...rest }) {
  return (
    <ion-icon
      name={name}
      class={` ${className ? className : ''} icon`}
      {...rest}
    ></ion-icon>
  );
}

export default Icon;
