import FormHeader from '../../../../../components/common/FormHeader';
import { Link } from 'react-router-dom';

const PasswordChanged = () => {
  return (
    <div className="vertical-center">
      <div className="form-reset-new">
        <FormHeader title="Your password was changed successfully." />

        <Link to="/signin" className="btn btn-primary btn-block mb-3">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default PasswordChanged;
