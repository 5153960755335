import http from "./httpService";

const apiEndPoint = "/services";

function url(id) {
  return `${apiEndPoint}/${id}`;
}

export function getServices() {
  return http.get(apiEndPoint);
}

export function getService(id) {
  return http.get(url(id));
}

export async function saveService(service) {
  const body = { ...service };

  if (service._id) {
    delete body._id;
    const updatedService = await http.put(url(service._id), body);
    return updatedService;
  }

  return http.post(apiEndPoint, body);
}

export function deleteService(id) {
  return http.delete(url(id));
}

export function deleteSongInService(serviceId, songId) {
  return http.delete(`${apiEndPoint}/songs/${serviceId}/${songId}`);
}

const serviceService = {
  getServices,
  getService,
  saveService,
  deleteService,
  deleteSongInService,
};

export default serviceService;
