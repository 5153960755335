import auth from '../../../services/authService';
import theme from '../../../utils/theme';

const SignOut = () => {
  auth.signout();
  window.location = '/signin';
  theme.removeDarkTheme();

  return null;
};

export default SignOut;
