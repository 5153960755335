import Sortable from "sortablejs";

export const sortItem = (item, items, direction) => {
  const index = items.indexOf(item);
  const destination = direction === "down" ? index + 1 : index - 1;

  items.splice(index, 1); // delete from current position
  items.splice(destination, 0, item); // insert in new position

  return items;
};

export const createSortable = (el, onSortEnd) => {
  Sortable.create(el, {
    animation: 150,
    handle: ".drag-handle",
    onEnd: function (evt) {
      onSortEnd(evt.oldIndex, evt.newIndex);
    },
  });
};
