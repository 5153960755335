import { Link } from 'react-router-dom';
import FormHeader from '../common/FormHeader';
import { setDocTitle } from '../../utils/utilities';
import './Faq.css';

const Faq = () => {
  setDocTitle('FAQ');
  return (
    <div className="container">
      <div className="faq">
        <FormHeader />
        <div className="topic">
          <Link id="team-id" to="#"></Link>
          <h5 className="topic-title">What is a team id?</h5>
          <div className="topic-desc">
            <p>
              Users who create a team when signing up will get a team id. Someone with your team id can join your team
              without an invitation link when they sign up (Only if your team is accepting new members).
            </p>
          </div>
        </div>

        <div className="topic">
          <Link id="join-create-team" to="#"></Link>
          <h5 className="topic-title">Join or Create a Team?</h5>
          <div className="topic-desc">
            <p>
              You can join a team with an invitation link or a team id. A worship leader would create a team and send an
              invitation link or team id to teammates so they can join the team. An invitation link will take you
              straight to the sign up page to join the team without the need for a team id.
            </p>
          </div>
        </div>

        <div className="topic">
          <Link id="public-songs" to="#"></Link>
          <h5 className="topic-title">Public Songs</h5>
          <div className="topic-desc">
            <p>
              A public song can be found by other teams when searching for public songs. Other teams can view and add a
              copy of a public song to their songs.
            </p>
            <p>
              When another team adds a copy of a public song, it can be edited but can't be set to public as it is a
              copy. It will show "Published By [Team Name]" when editing the song. The concept behind public songs is to
              make teams able to collaborate together.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
