import { $ } from '../../../../../utils/utilities';
import { useViewHeaderStore } from '../../ViewHeader/ViewHeader';
import { transpose, getNewKey } from '../../../../../utils/transpose';
import MinusIcon from '../../../../../images/Minus';
import PlusIcon from '../../../../../images/Plus';

const TransposePlugin = ({ target }) => {
  const setSongKey = useViewHeaderStore((state) => state.setSongKey);
  const songKey = useViewHeaderStore((state) => state.songKey);

  function getChords() {
    return $(target).getElementsByClassName('chord');
  }

  function handleSemitoneChange(count, direction) {
    setSongKey(getNewKey(songKey, direction));

    for (let i = 0; i < getChords().length; i++) {
      let currentChord = getChords()[i];

      let transposedChord = transpose(currentChord.innerHTML, songKey, count, direction);

      currentChord.innerHTML = transposedChord;
    }
  }

  return (
    <div className="song-plugin transpose-plugin">
      <span className="plugin__title">Transpose</span>
      <span onClick={() => handleSemitoneChange(1, 'down')} className="btn-decrease-value">
        <MinusIcon />
      </span>
      <span className="display-value">1/2</span>
      <span onClick={() => handleSemitoneChange(1, 'up')} className="btn-increase-value">
        <PlusIcon />
      </span>
    </div>
  );
};

export default TransposePlugin;
