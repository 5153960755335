import { ThreeDots } from 'svg-loaders-react';
import './Button.css';

const Button = ({ label, type, disabled, loading, className }) => {
  const setDisabled = () => loading || disabled;
  return (
    <button
      disabled={setDisabled()}
      type={type || 'submit'}
      className={`btn btn-primary mb-3 btn-block ${className || ''}`}
    >
      {loading ? <ThreeDots width="2em" className="svg-loader-button" /> : <span className="btn__text">{label}</span>}
    </button>
  );
};

export default Button;
