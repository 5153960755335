import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import auth from '../../../../services/authService';
import songService from '../../../../services/songService';

const SearchSongsResult = ({ songs }) => {
  const history = useHistory();
  const user = auth.getCurrentUser();

  const mapOwnSong = (song) => {
    if (song.team._id === auth.getCurrentUser().teamId) {
      song.ownSong = true;
      return song;
    } else {
      return song;
    }
  };
  // Add a ownSong property set to true when the song belongs to the team searching songs
  const updatedSongs = songs.map(mapOwnSong);

  return (
    <div className="song-results">
      <div className="row">
        {updatedSongs.map((song) => (
          <div key={song._id} className="col-lg-6">
            <div className="song-box">
              <strong>Title: </strong>
              <span>{song.title}</span>
              <br />
              <strong>Artist: </strong>
              <span>{song.artist}</span>
              <br />
              <strong>Key: </strong>
              <span>{song.key}</span>
              <br />
              <strong>Team: </strong>
              <span>{song.team.name}</span>
              <br />
              <Link className="btn btn-primary btn-sm link-btn" to={`/view/${song._id}`}>
                View Song
              </Link>
              {!song.ownSong && user && user.isAdmin && (
                <button
                  onClick={() => addSongCopyToTeam(song._id, history)}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  Add Song
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const addSongCopyToTeam = async (songId, history) => {
  try {
    const songFromDb = await songService.getSong(songId);
    const formattedSong = formatCopySong(songFromDb);

    await songService.saveSong(formattedSong);

    history.push('/songs');
  } catch (ex) {}
};

const formatCopySong = (song) => {
  // format the song as a new song to be saved
  // but by another team instead of the original one
  const songCopy = { ...song };

  songCopy.isPublic = false;
  songCopy.categoryId = songCopy.category._id;
  songCopy.teamId = auth.getCurrentUser().teamId;

  // optional properties that can be empty
  if (!songCopy.bpm) songCopy.bpm = '';
  if (!songCopy.youtube) songCopy.youtube = '';

  // new properties
  songCopy.isCopy = true;
  songCopy.originalTeam = { _id: songCopy.team._id, name: songCopy.team.name };

  delete songCopy._id;
  delete songCopy.__v;
  delete songCopy.category;
  delete songCopy.team;
  delete songCopy.createdAt;

  return songCopy;
};

export default SearchSongsResult;
