const ViewNextSongBtn = ({ onNext, nextSongTitle, nextSongPosition }) => {
  if (!nextSongTitle) return null;

  return (
    <div className="view-body__next-button text-ellipsis" onClick={onNext}>
      {`#${nextSongPosition} ${nextSongTitle}`}
    </div>
  );
};

export default ViewNextSongBtn;
