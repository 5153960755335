import Icon from "./Icon";

const PartEditControl = ({ part, onPartEdit }) => {
  return (
    <span className="clickable">
      <Icon name="create-outline" onClick={() => onPartEdit(part)} />
    </span>
  );
};

export default PartEditControl;
