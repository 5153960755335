import "./TagGroup.css";

const TagGroup = ({ name, classes, tags, selectedTagId, onTagClick }) => {
  return (
    <div className="tag-group horizontal-menu">
      <ul className={`${name} ${classes ? classes : ""}`}>
        {tags.map((tag) => (
          <li
            onClick={() => onTagClick(tag)}
            key={tag._id}
            className={tag._id === selectedTagId ? "active" : ""}
          >
            <span className="tag-group__tag-text">{tag.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TagGroup;
