import { useEffect, useState } from 'react';
import { $ } from '../../../../../utils/utilities';
import MinusIcon from '../../../../../images/Minus';
import PlusIcon from '../../../../../images/Plus';

const FontSizePlugin = ({ target }) => {
  const [currentFontSize, setCurrentFontSize] = useState('16px');
  const fontSizes = ['12px', '14px', '16px', '18px', '20px', '24px', '32px'];
  const sizeLabels = {
    '12px': '12',
    '14px': '14',
    '16px': '16',
    '18px': '18',
    '20px': '20',
    '24px': '24',
    '32px': '32',
  };
  const FONT_SIZE_KEY = 'fontSize';

  useEffect(() => {
    initCurrentFontSize();
  }, []);

  const initCurrentFontSize = () => {
    const currentFontSizeValue = localStorage.getItem(FONT_SIZE_KEY);
    if (currentFontSizeValue) setCurrentFontSize(currentFontSizeValue);
  };

  const setLocalCurrentFontSize = (fontSize) => {
    localStorage.setItem(FONT_SIZE_KEY, fontSize);
  };

  const handleIncreaseFontSize = () => {
    let index = fontSizes.indexOf(currentFontSize);

    const nextSize = fontSizes[index + 1];
    if (!nextSize) return; // last item

    $(target).style.fontSize = nextSize;
    setLocalCurrentFontSize(nextSize);
    setCurrentFontSize(nextSize);
  };

  const handleDecreaseFontSize = () => {
    let index = fontSizes.indexOf(currentFontSize);

    const previousSize = fontSizes[index - 1];
    if (!previousSize) return; // first item

    $(target).style.fontSize = previousSize;
    setLocalCurrentFontSize(previousSize);
    setCurrentFontSize(previousSize);
  };

  return (
    <div className="song-plugin font-size-plugin">
      <span className="plugin__title">Text Size</span>
      <span onClick={handleDecreaseFontSize} className="btn-decrease-value">
        <MinusIcon />
      </span>
      <span className="display-value">{sizeLabels[currentFontSize]}</span>
      <span onClick={handleIncreaseFontSize} className="btn-increase-value">
        <PlusIcon />
      </span>
    </div>
  );
};

export default FontSizePlugin;
