import { useEffect, useState } from 'react';
import Hammer from 'react-hammerjs';
import ViewSongPart from '../ViewSongPart';
import SongCount from '../SongCount';
import ViewNextSongBtn from '../ViewNextSongBtn';
import { settingKeys, getSetting, setSetting } from '../../../../utils/settings';

const ViewBody = ({ song, songs, service, onPrevious, onNext }) => {
  const [currentFontSize, setCurrentFontSize] = useState('16px');
  const stopScrollingKey = settingKeys.stopScrollingKey;

  useEffect(() => {
    getFontSize();
    setSetting(stopScrollingKey, false);
  });

  const handleStopScrolling = () => {
    const stopScrolling = getSetting(stopScrollingKey);

    if (!stopScrolling) {
      setSetting(stopScrollingKey, true);
    } else {
      setSetting(stopScrollingKey, !stopScrolling);
    }
  };

  const getFontSize = () => {
    const localFontSize = localStorage.getItem('fontSize');
    if (localStorage) setCurrentFontSize(localFontSize);
  };

  const handleSwipe = (e) => {
    if (service) {
      if (e.deltaX < 0) onNext();
      else onPrevious();
    }
  };

  const getCurrentSongPosition = () => {
    const index = service?.songsIds.indexOf(song._id);
    return index + 1;
  };

  const getNextSongTitle = () => {
    const currentSongIndex = songs.findIndex((s) => s._id === song._id);

    const nextSongTitle = songs[currentSongIndex + 1]?.title;

    if (!nextSongTitle) return;

    return nextSongTitle;
  };

  return (
    <Hammer onSwipe={handleSwipe}>
      <div onClick={handleStopScrolling} style={{ fontSize: currentFontSize }} id="view-body">
        {service && <SongCount service={service} song={song} songPosition={getCurrentSongPosition()} />}

        {song.parts.map((part) => (
          <ViewSongPart key={part.id} part={part} />
        ))}

        {service && (
          <ViewNextSongBtn
            onNext={onNext}
            nextSongTitle={getNextSongTitle()}
            nextSongPosition={getCurrentSongPosition() + 1}
          />
        )}
      </div>
    </Hammer>
  );
};

export default ViewBody;
