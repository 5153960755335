import { useState } from 'react';
import PropTypes from 'prop-types';
import AutoScrollPlugin from '../plugins/AutoScrollPlugin';
import TransposePlugin from '../plugins/TransposePlugin';
import FontSizePlugin from '../plugins/FontSizePlugin';
import HideChordsPlugin from '../plugins/HideChordsPlugin';

const ViewSongPlugins = ({ hideChords, setHideChords }) => {
  const [target] = useState('view-body');

  return (
    <div className="song-plugins horizontal-menu">
      <AutoScrollPlugin />
      <TransposePlugin target={target} />
      <FontSizePlugin target={target} />
      <HideChordsPlugin hideChords={hideChords} setHideChords={setHideChords} />
    </div>
  );
};

ViewSongPlugins.propTypes = {
  hideChords: PropTypes.bool,
  setHideChords: PropTypes.func,
};

export default ViewSongPlugins;
