const Textarea = ({ name, value, error, onChange }) => {
  return (
    <>
      <div className="form-group">
        <textarea
          rows="12"
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          className="form-control"
        ></textarea>
        {error && <small className="form-text text-danger">{error}</small>}
      </div>
    </>
  );
};

export default Textarea;
