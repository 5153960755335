import { Link } from "react-router-dom";
import Logo from "../../images/mg_logo.png";

const FormHeader = ({ title }) => {
  return (
    <>
      <Link to="/">
        <img src={Logo} alt="migrupo logo" className="form-logo" />
      </Link>

      {title && <p className="text-center">{title}</p>}
    </>
  );
};

export default FormHeader;
