import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import theme from '../../utils/theme';
import Icon from '../common/Icon';
import PartEditControl from '../common/PartEditControl';
import PartDuplicateControl from '../common/PartDuplicateControl';
import PartDeleteControl from '../common/PartDeleteControl';

function SongPart({ part, partIndex, onPartDelete, onPartDuplicate, onPartEdit, editPartId }) {
  return (
    <li className={theme.renderPartColor(part.name, 'song-parts__part effect')}>
      <span className="song-parts__info">
        <Icon name="musical-notes" className="drag-handle" />
        <Tooltip placement="left" trigger={['click']} overlay={<span className="lyrics-tooltip">{part.lyrics}</span>}>
          <span className="song-parts__name clickable">
            {part.name} {part.number}
          </span>
        </Tooltip>
      </span>

      {!editPartId && (
        <div className="song-parts__controls">
          {!part.isDuplicate && <PartDuplicateControl onPartDuplicate={onPartDuplicate} part={part} />}
          <PartEditControl part={part} onPartEdit={onPartEdit} />

          <PartDeleteControl
            onPartDelete={onPartDelete}
            part={part}
            partIndex={partIndex}
            disabled={part.hasDuplicates}
          />
        </div>
      )}
    </li>
  );
}

export default SongPart;
