import Icon from "./Icon";

const PartDeleteControl = ({ onPartDelete, part, partIndex, disabled }) => {
  return (
    <span className={`btn-delete ${disabled ? "no-action" : "clickable"}`}>
      <Icon
        name="close-circle-outline"
        onClick={() => !disabled && onPartDelete(part, partIndex)}
      />
    </span>
  );
};

export default PartDeleteControl;
