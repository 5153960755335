const settingsKey = 'settings';

function getSettings() {
  let settings = JSON.parse(localStorage.getItem(settingsKey));
  if (!settings) return {};
  return settings;
}

export function getSetting(settingKey) {
  let settings = getSettings();
  return settings[settingKey];
}

export function setSetting(name, value) {
  let settings = getSettings();

  settings[name] = value;
  localStorage.setItem(settingsKey, JSON.stringify(settings));

  return settings;
}

export const settingKeys = {
  stopScrollingKey: 'stopScrolling',
  hideChordsPlugin: 'hide-chords-plugin/hide-chords',
};
