import parse from 'html-react-parser';
import theme from '../../../../utils/theme';

const ViewSongPart = ({ part }) => {
  const matchChords = (part) => {
    let str = part;

    const re = /([A-G](?:#|b)?(?:m|min)?(?:sus|aug|dim|add|M)?\d*)(?=$|\s|\/|-|\r)(?! [a-z])/g;

    str = str.replace(re, (matched) => `<span class='chord'>${matched}</span>`);

    return str;
  };

  const matchComments = (part) => {
    let str = part;

    const re = /\[.*?\]/g; // Match any string within brackets

    str = str.replace(re, (matched) => {
      let onlyComment = matched.slice(1, -1);
      return `<span class='part-comment'><span class="comment">${onlyComment}</span></span>`;
    });

    return str;
  };

  const formatPart = (part) => matchComments(matchChords(part));

  return (
    <span key={part.id}>
      <span className={theme.renderPartColor(part.name, 'music-tag')}>
        <span className="music-tag__name">{`${part.name} ${part.number}`}</span>
      </span>
      <br />
      <span className={`music-tag__${part.name.toLowerCase()}`}>{parse(formatPart(part.lyrics))}</span>
      <br />
      <br />
    </span>
  );
};

export default ViewSongPart;
