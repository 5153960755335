import Icon from "./Icon";

const PartDuplicateControl = ({ part, onPartDuplicate }) => {
  return (
    <span className="clickable">
      <Icon name="copy-outline" onClick={() => onPartDuplicate(part)} />
    </span>
  );
};

export default PartDuplicateControl;
