import Icon from "./Icon";

const Title = ({ name, icon }) => {
  return (
    <h5 className="title">
      <Icon name={icon} /> <span> {name}</span>
    </h5>
  );
};

export default Title;
