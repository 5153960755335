import Icon from '../common/Icon';
import './TopMenu.css';

const TopMenu = ({ menu }) => {
  return (
    <div className="top-menu horizontal-menu sticky">
      <div className="container">
        <ul className="top-menu__items">
          {menu.map((menuItem, index) => (
            <li
              onClick={menuItem.handler}
              className="top-menu__item clickable"
              key={index}
            >
              <Icon name={menuItem.icon} />
              <span className="top-menu__title">{menuItem.title}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TopMenu;
