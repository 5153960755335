const Input = ({ name, value, label, type, error, onChange }) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <input
        id={name}
        name={name}
        value={value}
        placeholder={label}
        type={type}
        onChange={onChange}
        className={`form-control ${name}`}
      />
      {error && <small className="form-text text-danger">{error}</small>}
    </div>
  );
};

export default Input;
