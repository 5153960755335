function findChord(lyricString) {
  const chordRX = new RegExp(/([A-G](?:#|b)?(?:m|min)?(?:sus|aug|dim|add|M)?\d*)(?=$|\s|\/|-|\r)(?! [a-z])/g);

  let hasChord = chordRX.test(lyricString);

  return hasChord;
}

export function removeChords(songPart) {
  const excludedParts = ['Note'];
  const instrumentParts = ['Intro', 'Music', 'Instrumental', 'Outro'];
  let lyrics = songPart.lyrics;
  let lines = lyrics.split(/\r\n|\r|\n/);

  // Skip excluded parts
  if (excludedParts.includes(songPart.name)) return songPart;

  // Test if any of the lyric lines have a chord
  if (!instrumentParts.includes(songPart.name)) {
    lines.forEach((line, index) => {
      if (findChord(line)) {
        lines[index] = null;
      } else {
        // Remove any extra white spaces
        let str = line.replace(/\s\s+/g, ' ').trimStart().trimEnd();
        lines[index] = str;
      }
    });
  }

  // Musical Parts | Add '♫' to any instrument parts
  if (instrumentParts.includes(songPart.name)) {
    songPart.lyrics = '♫';
  } else {
    // Other Parts
    // Remove lines with chords
    lines = lines.filter((line) => line !== null);
    // Join remaining lyric lines together
    songPart.lyrics = lines.join('\r');
  }

  return songPart;
}

export function removeSongChords(song) {
  const songParts = JSON.parse(JSON.stringify(song.parts)); // ? 👀

  songParts.forEach((songPart, index) => {
    songParts[index] = removeChords(songPart);
  });

  return songParts;
}
