import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import Form from '../../../../common/Form';
import FormHeader from '../../../../../components/common/FormHeader';
import userService from '../../../../../services/userService';
import PasswordChanged from '../PasswordChanged';

class NewPassword extends Form {
  state = {
    data: { password: '', confirmPassword: '' },
    errors: {},
    submitted: false,
  };

  schema = {
    password: Joi.string().min(8).max(255).required().label('Password'),
    confirmPassword: Joi.any()
      .valid(Joi.ref('password'))
      .required()
      .options({ language: { any: { allowOnly: 'must match' } } })
      .label('Passwords'),
  };

  doSubmit = async () => {
    try {
      const { userId, token } = this.props.match.params;
      await userService.changePassword(userId, token, this.state.data.password);
      this.setState({ submitted: true });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error('This password reset link is not valid.');
      }
    }
  };

  render() {
    switch (this.state.submitted) {
      case true:
        return <PasswordChanged />;
      default:
        return (
          <div className="vertical-center">
            <form onSubmit={this.handleSubmit} className="form-reset-new">
              <FormHeader title="" />
              {this.renderInput('password', 'New Password', 'password')}
              {this.renderInput('confirmPassword', 'Confirm Password', 'password')}
              {this.renderButton('Change Password')}
            </form>
          </div>
        );
    }
  }
}

export default NewPassword;
