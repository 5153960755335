import { useEffect, useState } from 'react';
import ServiceSong from '../ServiceSong';
import { createSortable } from '../../utils/sorting';
import './ServiceSongs.css';

function ServiceSongs({ currentService, onDeleteFromService, onDragSortInService }) {
  const [serviceSongsRef, setServiceSongsRef] = useState(null);

  useEffect(() => {
    if (serviceSongsRef) {
      createSortable(serviceSongsRef, onDragSortInService);
    }
  }, [serviceSongsRef, onDragSortInService]);

  return (
    <ul ref={(ref) => setServiceSongsRef(ref)} className="service-songs">
      {currentService.map((song) => (
        <ServiceSong key={song._id} song={song} onDeleteSong={onDeleteFromService} />
      ))}
    </ul>
  );
}

export default ServiceSongs;
