import ViewServiceBtn from '../common/ViewServiceBtn';
import Options from '../common/Options';
import auth from '../../services/authService';
import ServiceDate from '../ServiceDate';
import { getRemainingDays } from '../../utils/utilities';
import Icon from '../common/Icon';

const Service = ({ service, onViewService = null, onDelete = null, onEdit = null, editId = null }) => {
  const user = auth.getCurrentUser();

  const addRoleCustomClass = (defaultClass, role, customClass) => {
    return role ? `${customClass}` : defaultClass;
  };

  const renderViewService = (item, onViewService, editId) => {
    if (onViewService && !editId) return <ViewServiceBtn onViewService={onViewService} service={item} />;
  };

  const renderServiceTag = (tagClass, content) => {
    let classes = 'service__tag';
    classes += ` ${tagClass}`;

    return (
      <span className={classes}>
        <span className="service__tag-text">{content}</span>
      </span>
    );
  };

  const getRemainingDaysTag = (date) => {
    const remainingDays = getRemainingDays(date);

    let tag = '';
    let tagClass = '';

    if (remainingDays > 1) {
      tag = `in ${remainingDays} days`;
      tagClass = 'days';
    } else if (remainingDays === 1) {
      tag = 'Tomorrow';
      tagClass = 'tomorrow';
    } else if (remainingDays === 0) {
      tag = 'Today';
      tagClass = 'today';
    } else if (remainingDays === -1) {
      tag = 'Yesterday';
      tagClass = 'past';
    } else if (remainingDays < -1) {
      tag = `${Math.abs(remainingDays)} days ago`;
      tagClass = 'past';
    }

    return renderServiceTag(tagClass, tag);
  };

  const getSongsCountTag = (service) => {
    const tagContent = (
      <span className="song-count-tag" title="Total Song Count">
        <Icon name="musical-notes" className="total-count-icon" />
        {service.songsIds.length}
      </span>
    );

    return renderServiceTag('song-count', tagContent);
  };

  const generateYoutubePlaylist = (youtubeLinks) => {
    let baseUrl = 'https://www.youtube.com/watch_videos?video_ids=';
    const re =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const videoIds = [];

    // Get video ids
    youtubeLinks.forEach((link) => {
      const match = link.match(re);
      if (match) videoIds.push(match[1]);
    });

    // Append video ids
    videoIds.forEach((videoId) => (baseUrl += videoId + ','));

    // Delete last comma
    const playlistUrl = baseUrl.slice(0, -1);

    if (videoIds.length === 0) return;

    return playlistUrl;
  };

  const getYouTubePlaylistTag = () => {
    const playlistUrl = generateYoutubePlaylist(service.links);

    if (!playlistUrl) return;

    return renderServiceTag(
      'playlist',
      <a href={playlistUrl} target="_blank" rel="noreferrer">
        Playlist
      </a>
    );
  };

  return (
    <li className="service effect">
      <div className="service-flex-container">
        <div className="service-info">
          <ServiceDate item={service} />
          <p className="service-title">{' ' + service.title}</p>
        </div>
        <div className={addRoleCustomClass('service-actions', user.isAdmin, 'service-actions-a')}>
          {renderViewService(service, onViewService, editId)}
          {user && user.isAdmin && (
            <Options modalId="deleteService" item={service} onDelete={onDelete} onEdit={onEdit} editId={editId} />
          )}
        </div>
      </div>

      <div className="service__tags">
        {getSongsCountTag(service)}
        {getRemainingDaysTag(service.date)}
        {service.links && getYouTubePlaylistTag()}
      </div>
    </li>
  );
};

export default Service;
