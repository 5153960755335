function AutoScrollBtn({ scrollAt, speed, label, selected }) {
  const renderClass = () => {
    let baseClasses = 'auto-scroll-plugin__btn';
    if (selected !== null && selected === speed) {
      baseClasses += ' selected';
    }
    return baseClasses;
  };

  return (
    <span onClick={() => scrollAt(speed)} className={renderClass()}>
      <span>{label}</span>
    </span>
  );
}

export default AutoScrollBtn;
