import { Component } from 'react';
import { Link } from 'react-router-dom';
import Table from '../common/Table';
import AddToService from '../common/AddToService';
import Favorite from '../common/Favorite';
import Options from '../common/Options';
import NoContent from '../common/NoContent';
import authService from '../../services/authService';
import './SongsTable.css';

class SongsTable extends Component {
  constructor() {
    super();
    const user = authService.getCurrentUser();
    if (user && user.isAdmin) this.actionsColumns.forEach((column) => this.columns.push(column));
  }

  columns = [
    {
      path: 'title',
      label: 'Title',
      content: (song) => <Link to={`/view/${song._id}`}>{song.title}</Link>,
    },
    { path: 'artist', label: 'Artist' },
    {
      key: 'favorite',
      content: (song) => <Favorite onClick={() => this.props.onFavorite(song)} favorited={song.favorite} />,
    },
  ];

  actionsColumns = [
    {
      key: 'addToService',
      content: (song) => <AddToService onClick={() => this.props.onAddToService(song)} song={song} />,
    },
    {
      key: 'options',
      content: (song) => (
        <Options modalId="deleteSong" item={song} onDelete={this.props.onDelete} onEdit={this.props.onEdit} />
      ),
    },
  ];

  render() {
    const { songs, onSort, sortColumn, loading } = this.props;

    const songsCount = songs.length;

    return (
      <div className="songs__table">
        {songsCount !== 0 ? (
          <Table data={songs} columns={this.columns} sortColumn={sortColumn} onSort={onSort} />
        ) : (
          <NoContent loading={loading} message="No songs" />
        )}
      </div>
    );
  }
}

export default SongsTable;
