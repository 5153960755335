const serviceURL = 'https://migrupo.cc/view/service/';

function renderSongList(songsArray) {
  let string = '';

  songsArray.forEach((song, index) => {
    string += `#${index + 1} ${song.title}\n`;
  });

  return string;
}

function createBody(serviceTitle, serviceId, songs) {
  const body = {
    content: "💬 The 'Domingo' service was updated:",
    tts: false,
    color: 'white',
    embeds: [
      {
        title: 'View Service',
        description: '#1 Song\n#2 Song',
        url: 'https://migrupo.cc/view/service/64219fc625b4bc00149d4b4a',
      },
    ],
  };

  // get song list
  const songListString = renderSongList(songs);

  body.content = `💬 '${serviceTitle}' service has been updated!`;
  body.embeds[0].description = songListString;
  body.embeds[0].url = `${serviceURL}${serviceId}`;

  return body;
}

export default function sendDiscordMessage(currentState, service, updatedService) {
  const teamId = currentState.currentService[0].team._id;
  const { songsIds: originalServiceSongIds } = service;
  const { songsIds: updatedServiceSongIds } = updatedService;

  let newChanges = false;

  if (originalServiceSongIds.length === updatedServiceSongIds.length) {
    originalServiceSongIds.forEach((originalSongId, index) => {
      if (originalSongId !== updatedServiceSongIds[index] && updatedServiceSongIds !== 'undefined') {
        newChanges = true;
      }
    });
  } else {
    newChanges = true;
  }

  try {
    if (newChanges) {
      if (teamId === process.env.REACT_APP_VINO_NUEVO_TEAM_ID.toString()) {
        const serviceTitle = currentState.currentServiceTitle;
        const serviceId = currentState.editServiceId;

        const songs = currentState.currentService;

        const body = createBody(serviceTitle, serviceId, songs);

        fetch(process.env.REACT_APP_DISCORD_VINO_NUEVO_ID.toString(), {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
      }
    }
  } catch (error) {}
}
