import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { setDocTitle, scrollTop } from '../../utils/utilities';
import NoContent from '../../components/common/NoContent';
import songService from '../../services/songService';
import serviceService from '../../services/serviceService';
import ViewHeader from './components/ViewHeader';
import ViewBody from './components/ViewBody';
import Footer from '../../components/common/Footer';
import { useAutoScrollPluginStore } from './components/plugins/AutoScrollPlugin/AutoScrollPlugin';
import './View.css';
import { removeSongChords } from '../../utils/chord';
import { getSetting, settingKeys } from '../../utils/settings';

const View = () => {
  const [service, setService] = useState([]);
  const [currentSong, setCurrentSong] = useState({});
  const [songs, setSongs] = useState([]);
  const [lastSong, setLastSong] = useState(false);
  const [firstSong, setFirstSong] = useState(false);
  const [loadingSongs, setLoadingSongs] = useState(false);
  const [showPlugins, setShowPlugins] = useState(false);
  const [initialServiceFetch, setInitialServiceFetch] = useState(true);
  const params = useParams();
  const history = useHistory();
  // To stop autoscrolling correctly
  const scrollTimerId = useAutoScrollPluginStore((state) => state.scrollTimerId);
  const setSelectedBtn = useAutoScrollPluginStore((state) => state.setSelectedBtn);
  const setScrolling = useAutoScrollPluginStore((state) => state.setScrolling);

  // Track hide-chords-plugin
  const [hideChords, setHideChords] = useState(false);
  const [hidingChords, setHiddingChords] = useState(false);

  // Handle showing only lyrics
  // TODO: Investigue about deep copying an object
  // TODO: Better comment this code
  useEffect(() => {
    const songsCopy = JSON.parse(JSON.stringify(songs));

    if (hideChords && !hidingChords) {
      if (params.service) {
        if (songs.length > 0) {
          songsCopy.forEach((song) => {
            if (!song.originalParts) {
              song.originalParts = JSON.parse(JSON.stringify(song.parts));
              song.parts = removeSongChords(song);
            }
          });

          setHiddingChords(true);
          setSongs(songsCopy);
        }
      } else {
        const songCopy = JSON.parse(JSON.stringify(currentSong));

        if (!songCopy.originalParts) {
          songCopy.originalParts = JSON.parse(JSON.stringify(songCopy.parts));
          songCopy.parts = removeSongChords(songCopy);
        }

        setHiddingChords(true);
        setCurrentSong(songCopy);
      }
    } else if (!hideChords && hidingChords) {
      if (params.service) {
        songsCopy.forEach((song) => {
          if (song.originalParts) {
            song.parts = JSON.parse(JSON.stringify(song.originalParts));
            delete song.originalParts;
          }
        });

        setSongs(songsCopy);
        setHiddingChords(false);
      } else {
        if (currentSong) {
          const songCopy = JSON.parse(JSON.stringify(currentSong));

          if (songCopy.originalParts) {
            songCopy.parts = JSON.parse(JSON.stringify(songCopy.originalParts));
            delete songCopy.originalParts;
          }
          setCurrentSong(songCopy);
          setHiddingChords(false);
        }
      }
    }
  }, [hideChords, hidingChords, songs, params.service, currentSong]);

  useEffect(() => {
    // Playing a service
    if (params.service) {
      const fetchService = async () => {
        let service = [];
        const songs = [];

        if (initialServiceFetch) {
          setInitialServiceFetch(false);

          try {
            setLoadingSongs(true);

            const { data } = await serviceService.getService(params.id);
            service = data;

            // get service's songs
            for (let songId of service.songsIds) {
              const song = await songService.getSong(songId);
              song.parts = songService.loadDuplicateParts(song);

              // Remove chords from song if necessary
              if (getSetting(settingKeys.hideChordsPlugin)) {
                song.originalParts = JSON.parse(JSON.stringify(song.parts));
                song.parts = removeSongChords(song);
                setHiddingChords(true);
              }

              songs.push(song);
            }

            setService(service);
            setSongs(songs);
            setDocTitle(`${service.title} | Service`);

            // service has 0 songs
            if (service?.songsIds?.length === 0) return history.replace('/not-found');

            // disable next button for services with 1 song
            if (service?.songsIds?.length === 1) {
              setLastSong(true);
              setFirstSong(true);
            }

            setLoadingSongs(false);
          } catch (ex) {
            setLoadingSongs(false);
            handleException(ex);
          }
        }
      };
      fetchService();
    } else {
      // Playing a single song
      const fetchSong = async () => {
        try {
          setLoadingSongs(true);

          const song = await songService.getSong(params.id);
          song.parts = songService.loadDuplicateParts(song);

          // Remove chords from song if necessary
          if (getSetting(settingKeys.hideChordsPlugin)) {
            song.originalParts = JSON.parse(JSON.stringify(song.parts));
            song.parts = removeSongChords(song);
            setHiddingChords(true);
          }

          setCurrentSong(song);

          setDocTitle(`${song.title} - ${song.artist}`);

          // not a service, hide service controls
          setService(null);

          setLoadingSongs(false);
        } catch (ex) {
          setLoadingSongs(false);
          handleException(ex);
        }
      };
      fetchSong();
    }

    const handleException = (ex) => {
      if (ex.response) {
        if (ex.response.status === 404 || ex.response.status === 400) {
          history.replace('/not-found');
          return;
        }
      }
    };
  }, [params, history, initialServiceFetch]);

  // Set current song
  useEffect(() => {
    let hash = history.location.hash;
    let songIndex = null;

    if (!hash) {
      // the first song in the service
      songIndex = 0;
    } else {
      // hash is not 0 based
      songIndex = parseInt(hash.substring(1)) - 1;
    }

    if (songIndex >= 0 && songs[songIndex]) {
      setCurrentSong(songs[songIndex]);

      // check ahead to handle previous and next buttons
      !songs[songIndex - 1] ? setFirstSong(true) : setFirstSong(false);
      !songs[songIndex + 1] ? setLastSong(true) : setLastSong(false);
    } else {
      // set current song to the first song in the service
      setCurrentSong(songs[0]);
      setFirstSong(true);
      setLastSong(false);
    }
  }, [songs, history]);

  // Handle plugins display
  useEffect(() => {
    const showPlugins = JSON.parse(localStorage.getItem('showPlugins'));
    if (showPlugins) setShowPlugins(true);
  }, []);

  const handleShowPlugins = () => {
    // toggle plugins
    setShowPlugins(!showPlugins);
    localStorage.setItem('showPlugins', !showPlugins);
  };

  const stopAutoScrollOnSongChange = () => {
    clearInterval(scrollTimerId);
    setScrolling(false);
    setSelectedBtn(null);
  };

  const handlePreviousSong = () => {
    const index = songs.findIndex((s) => s._id === currentSong._id);

    let previousSong = null;
    previousSong = songs[index - 1];

    // check for first song
    if (!previousSong) return null;

    // hash is not 0 based, no need to subtract 1
    history.push(`#${index}`);

    setCurrentSong(previousSong);
    setLastSong(false);

    stopAutoScrollOnSongChange();
    scrollTop();

    // next song, first song
    if (!songs[index - 2]) {
      setFirstSong(true);
      return null;
    }
  };

  const handleNextSong = () => {
    const index = songs.findIndex((s) => s._id === currentSong._id);

    let nextSong = null;
    nextSong = songs[index + 1];

    // check for last song
    if (!nextSong) return null;

    // index is 0 based, add 2 (Hash is not 0 based)
    history.push(`#${index + 2}`);

    setCurrentSong(nextSong);
    setFirstSong(false);

    stopAutoScrollOnSongChange();
    scrollTop();

    // next song, last song
    if (!songs[index + 2]) {
      setLastSong(true);
      return null;
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="view-song">
              {service?.length === 0 || _.isEmpty(currentSong) ? (
                <NoContent loading={loadingSongs} />
              ) : (
                <>
                  <ViewHeader
                    song={currentSong}
                    service={service}
                    onPrevious={handlePreviousSong}
                    onNext={handleNextSong}
                    firstSong={firstSong}
                    lastSong={lastSong}
                    showPlugins={showPlugins}
                    handleShowPlugins={handleShowPlugins}
                    hideChords={hideChords}
                    setHideChords={setHideChords}
                  />
                  <ViewBody
                    song={currentSong}
                    songs={songs}
                    service={service}
                    onPrevious={handlePreviousSong}
                    onNext={handleNextSong}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer marginBottom={7} />
    </>
  );
};

export default View;
