const Input = ({ name, value, label, error, onChange }) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <input
        id={name}
        name={name}
        value={value}
        type="number"
        onChange={onChange}
        className="form-control"
        min="1"
        max="99"
      />
      {error && <small className="form-text text-danger">{error}</small>}
    </div>
  );
};

export default Input;
