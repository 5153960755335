import http from "./httpService";

const apiEndPoint = "/users";

// function url(id) {
//   return `${apiEndPoint}/${id}`;
// }

function available(email) {
  return http.get(`${apiEndPoint}/email/${email}`);
}

function register(user) {
  return http.post(apiEndPoint, {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: user.password,
    teamId: user.teamId,
    isAdmin: user.isAdmin,
  });
}

function resetPassword(email) {
  return http.post(`${apiEndPoint}/resetpassword`, {
    email: email,
  });
}

function changePassword(userId, token, newPassword) {
  return http.post(`${apiEndPoint}/resetpassword/new/${userId}/${token}`, {
    password: newPassword,
  });
}

function saveFavoriteSong(songId) {
  return http.patch(`${apiEndPoint}/favorites/${songId}`, {});
}

function removeFavoriteSong(songId) {
  return http.delete(`${apiEndPoint}/favorites/${songId}`);
}

function getFavoriteSongs() {
  return http.get(`${apiEndPoint}/favorites`);
}

const userService = {
  available,
  register,
  saveFavoriteSong,
  removeFavoriteSong,
  getFavoriteSongs,
  resetPassword,
  changePassword,
};
export default userService;
