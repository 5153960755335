import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { getSetting, setSetting } from '../../../../../utils/settings';
import './HideChordsPlugin.css';

const HideChordsPlugin = ({ hideChords, setHideChords }) => {
  const settingKey = 'hide-chords-plugin/hide-chords';

  function handleHideChords() {
    const hideChordsSetting = getSetting(settingKey);

    // Save setting state to local storage
    if (!hideChordsSetting) {
      setSetting(settingKey, true);
      setHideChords(true);
    } else {
      setSetting(settingKey, false);
      setHideChords(false);
    }
  }

  useLayoutEffect(() => {
    const hideChordsSetting = getSetting(settingKey);
    // Display current plugin state
    if (hideChordsSetting) setHideChords(true);
  }, []);

  return (
    <div className="song-plugin hide-chords-plugin">
      <span className="plugin__title">Chords</span>
      <div className={`plugin__body ${hideChords ? 'off' : 'on'}`} onClick={handleHideChords}>
        <span className="plugin__toggle">{hideChords ? 'OFF' : 'ON'}</span>
      </div>
    </div>
  );
};

HideChordsPlugin.propTypes = {
  currentSong: PropTypes.object,
  setCurrentSong: PropTypes.func,
};

export default HideChordsPlugin;
