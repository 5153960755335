export const applyTheme = () => {
  const darkThemeEnabled = localStorage.getItem('darkTheme');
  if (darkThemeEnabled && darkThemeEnabled === 'true') {
    document.body.classList.replace('light', 'dark');
  }
};

export const getTheme = (themeName) => {
  return localStorage.getItem(themeName);
};

export const setDarkTheme = () => {
  localStorage.setItem('darkTheme', true);
  document.body.classList.replace('light', 'dark');
};

export const removeDarkTheme = () => {
  localStorage.setItem('darkTheme', false);
  document.body.classList.replace('dark', 'light');
};

export const renderPartColor = (partName, baseClasses) => {
  let classes = baseClasses;

  if (partName === 'Intro') classes += ' intro';
  if (partName === 'Verse') classes += ' verse';
  if (partName === 'Pre-chorus') classes += ' pre-chorus';
  if (partName === 'Chorus') classes += ' chorus';
  if (partName === 'Bridge') classes += ' bridge';
  if (partName === 'Music') classes += ' music';
  if (partName === 'Instrumental') classes += ' instrumental';
  if (partName === 'Outro') classes += ' outro';
  if (partName === 'Refrain') classes += ' refrain';
  if (partName === 'Note') classes += ' note';

  return classes;
};

const theme = {
  applyTheme,
  getTheme,
  setDarkTheme,
  removeDarkTheme,
  renderPartColor,
};

export default theme;
