import Icon from '../../../../components/common/Icon';

const SearchSongsNotFound = ({ searchQuery }) => {
  return (
    <div className="not-found">
      <Icon name="information-circle" />
      <p>No Public Songs found for "{searchQuery}".</p>
      <p className="text-color">
        <strong>Be the first to add it and make it public!</strong>
      </p>
    </div>
  );
};

export default SearchSongsNotFound;
