const categories = [
  { _id: "5eb5781aacffed35e883809a", name: "Praise" },
  { _id: "5eb57828acffed35e883809b", name: "Worship" },
];

export function getCategories() {
  return categories;
}

export function getCategory(categoryId) {
  return categories.find((c) => c._id === categoryId);
}
