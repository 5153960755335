import scales from '../data/scales.json';

const { FLAT_SCALE, SHARP_SCALE, PREFERRED_FLAT_SCALES, MAJOR_SCALE_KEYS, MINOR_SCALE_KEYS } = scales;

const flags = {
  hasSharp: false,
  hasFlat: false,
  hasMinor: false,
  hasSuffix: false,
  suffix: '',
  hasDigit: false,
  digit: 0,
};

function setFlags(chord) {
  if (/#/.test(chord)) flags.hasSharp = true;
  if (/b/.test(chord)) flags.hasFlat = true;
  if (/m/.test(chord)) flags.hasMinor = true;

  if (/(sus|aug|dim|add|M)/.test(chord)) flags.hasSuffix = true;
  if (flags.hasSuffix) flags.suffix = /(sus|aug|dim|add|M)/.exec(chord)[0];

  if (/\d/.test(chord)) flags.hasDigit = true;
  if (flags.hasDigit) flags.digit = /\d/.exec(chord)[0];
}

function resetFlags() {
  flags.hasSharp = false;
  flags.hasFlat = false;
  flags.hasMinor = false;
  flags.hasSuffix = false;
  flags.suffix = '';
  flags.hasDigit = false;
  flags.digit = 0;
}

function setSuffix(chord) {
  let updatedChord = chord;

  if (flags.hasMinor) updatedChord += 'm';
  if (flags.hasSuffix) updatedChord += flags.suffix;
  if (flags.hasDigit) updatedChord += flags.digit;

  return updatedChord;
}

export function getNewKey(currentSongKey, direction) {
  let scaleDelimiter = direction === 'up' ? 0 : 11;

  let scale = MAJOR_SCALE_KEYS;
  let currentKeyIndex = scale.indexOf(currentSongKey);

  // It's a minor scale key
  if (currentKeyIndex < 0) {
    scale = MINOR_SCALE_KEYS;
    currentKeyIndex = scale.indexOf(currentSongKey);
  }

  // Choose which direction the scale is being transpose to
  let newScale = direction === 'up' ? scale[currentKeyIndex + 1] : scale[currentKeyIndex - 1];

  // First or Last key in the scale array, jump to the start or end
  if (!newScale) newScale = scale[scaleDelimiter];

  return newScale;
}

export function transpose(chord, songKey, count, direction) {
  // Go to the start or end of the array when the scale reaches its first or last degree
  let scaleDelimiter = direction === 'up' ? 0 : 11;
  let transposedChord = '';

  // Break chord into parts
  setFlags(chord);

  if (flags.hasSharp || flags.hasFlat) {
    if (flags.hasSharp) {
      const chordIndex = SHARP_SCALE.indexOf(chord.substr(0, 2));

      transposedChord = direction === 'up' ? SHARP_SCALE[chordIndex + count] : SHARP_SCALE[chordIndex - count];

      // Jump to the start or end of the array
      if (!transposedChord) transposedChord = SHARP_SCALE[scaleDelimiter];
    } else {
      const chordIndex = FLAT_SCALE.indexOf(chord.substr(0, 2));

      transposedChord = direction === 'up' ? FLAT_SCALE[chordIndex + count] : FLAT_SCALE[chordIndex - count];

      // Jump to the start or end of the array
      if (!transposedChord) transposedChord = FLAT_SCALE[scaleDelimiter];
    }
  } else {
    // No Sharp nor Flat chords
    let scale = [];

    scale = PREFERRED_FLAT_SCALES.includes(getNewKey(songKey, direction)) ? FLAT_SCALE : SHARP_SCALE;

    const chordIndex = scale.indexOf(chord.substr(0, 1));

    transposedChord = direction === 'up' ? scale[chordIndex + count] : scale[chordIndex - count];

    // Jump to the start or end of the array
    if (!transposedChord) transposedChord = scale[scaleDelimiter];
  }

  transposedChord = setSuffix(transposedChord);

  resetFlags();

  return transposedChord;
}
