import http from "./httpService";

const apiEndPoint = "/teams";

export function getTeam(teamId) {
  return http.get(url(teamId));
}

export function createTeam(teamName) {
  return http.post(apiEndPoint, { name: teamName });
}

export function allowJoining(value) {
  const body = {
    allow: value,
  };
  return http.patch(`${apiEndPoint}/allowJoining`, body);
}

function url(id) {
  return `${apiEndPoint}/${id}`;
}
