import http from "./httpService";
import _ from "lodash/lang";

const apiEndPoint = "/songs";

function url(id) {
  return `${apiEndPoint}/${id}`;
}

function formatSong(song) {
  let songBody = { ...song };
  // When copying songs between teams a song won't have these 3 properties
  if (songBody.currentPart) {
    delete songBody.currentPart;
    delete songBody.currentLyrics;
    delete songBody.partNumber;
  }

  songBody.parts = formatDuplicateParts(songBody);

  return songBody;
}

function formatDuplicateParts(song) {
  const { parts } = _.cloneDeep(song);

  const duplicates = parts.filter((p) => p.isDuplicate === true);

  // Empty duplicates
  duplicates.forEach((d) => {
    d.name = "";
    d.number = "";
    d.lyrics = "";
  });

  return parts;
}

function loadDuplicateParts(song) {
  const { parts } = song;
  const duplicates = parts.filter((p) => p.isDuplicate === true);

  duplicates.forEach((d) => {
    const originalPart = parts.find((p) => p.id === d.duplicateId);

    d.name = originalPart.name;
    d.number = originalPart.number;
    d.lyrics = originalPart.lyrics;
  });

  return parts;
}

export async function getSongs() {
  const { data: songs } = await http.get(apiEndPoint);
  return songs;
}

export async function getSong(songId) {
  const { data: song } = await http.get(url(songId));
  return song;
}

export async function saveSong(song) {
  const body = formatSong(song);

  // Updating a song
  if (song._id) {
    delete body._id;
    const updatedSong = await http.put(url(song._id), body);
    return updatedSong;
  }

  const newSong = await http.post(apiEndPoint, body);
  return newSong;
}

export function getSearchSongs(title) {
  return http.get(`${apiEndPoint}/searchsongs/${title}`);
}

export function deleteSong(songId) {
  return http.delete(url(songId));
}

const songService = {
  getSongs,
  getSong,
  getSearchSongs,
  saveSong,
  deleteSong,
  loadDuplicateParts,
};

export default songService;
