import Joi from 'joi-browser';
import FormHeader from '../../../../common/FormHeader';
import Form from '../../../../common/Form';
import BackButton from '../BackButton';

class CreateTeam extends Form {
  state = { data: { teamName: '' }, errors: {} };

  schema = {
    teamName: Joi.string().max(60).required().label('Team Name'),
  };

  doSubmit = () => {
    this.props.onCreateTeam(this.state.data.teamName);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="form-signup">
        <BackButton handler={this.props.onGoBack} />
        <FormHeader title="Enter Team Name" />
        <div className="no-new-teams">
          ⚠️ Migrupo is currently not accepting new teams. We apologize for any inconvenience this may cause.
        </div>
        {this.renderInput('teamName', 'Team Name', 'text')}
        {this.renderButton('Create Team', this.props.callingApi)}
      </form>
    );
  }
}

export default CreateTeam;
