export const $ = (id) => document.getElementById(id);

export const scrollTop = (targetId) => {
  if (targetId) {
    $(targetId).scrollIntoView({ behavior: "smooth" });
  } else {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome
  }
};

export const scrollBottom = (id) => {
  const el = $(id);
  el.scrollTop = el.scrollHeight;
};

export const setDocTitle = (title) => {
  document.title = `${title} - Migrupo`;
};

export const getRemainingDays = (date) => {
  const d = new Date(date);
  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const remainingDays = Math.ceil((d.getTime() - today.getTime()) / oneDay);

  return remainingDays;
};
