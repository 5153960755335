import Joi from 'joi-browser';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../../images/mg_logo.png';
import Form from '../../common/Form';
import auth from '../../../services/authService';
import { setDocTitle } from '../../../utils/utilities';

class SignIn extends Form {
  state = {
    data: { email: '', password: '' },
    errors: {},
    signingIn: false,
  };

  schema = {
    email: Joi.string().required().email().label('Email address'),
    password: Joi.string().required().label('Password'),
  };

  componentDidMount() {
    setDocTitle('Sign In');
  }

  doSubmit = async () => {
    try {
      this.setState({ signingIn: true });

      const { data } = this.state;
      await auth.signin(data.email, data.password);

      // Redirect the user to where they came from
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : '/';
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
      this.setState({ signingIn: false });
    }
  };

  render() {
    // if there is a user, redirect it to homepage
    if (auth.getCurrentUser()) return <Redirect to="/songs" />;

    return (
      <div className="vertical-center">
        <form onSubmit={this.handleSubmit} className="form-signin">
          <Link to="/">
            <img src={Logo} alt="migrupo logo" className="form-logo" />
          </Link>

          {this.renderInput('email', 'Email', 'email')}
          {this.renderInput('password', 'Password', 'password')}
          <Link to="/resetpassword">
            <small className="d-block text-right forgot-password">Forgot your password?</small>
          </Link>
          {this.renderButton('Sign In', this.state.signingIn)}

          <div className="text-center">
            <span>or </span>
            <Link to="/signup">Create an account</Link>
          </div>
        </form>
      </div>
    );
  }
}

export default SignIn;
