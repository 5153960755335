import { Link } from "react-router-dom";
import Icon from "./Icon";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ name, value, checked, label, onChange, helpPath }) => {
  return (
    <div className={name}>
      <span>{label}</span>
      {helpPath && (
        <Link className="switch-help" to={`/faq#${helpPath}`}>
          <Icon name="help-circle" />
        </Link>
      )}
      <span className="switch-container">
        <label className="switch">
          <input
            type="checkbox"
            name={name}
            onChange={onChange}
            checked={checked}
            value={value}
          />
          <span className="slider round"></span>
        </label>
      </span>
    </div>
  );
};

export default ToggleSwitch;
