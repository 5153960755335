import Icon from "./Icon";

const Favorite = ({ favorited, onClick }) => {
  let iconType = "heart";
  if (!favorited) iconType += "-outline";

  return (
    <div className="d-block text-right clickable">
      <Icon name={iconType} onClick={onClick} />
    </div>
  );
};

export default Favorite;
