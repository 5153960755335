import { useContext } from "react";
import ServiceContext from "../context/serviceContext";
import Icon from "../common/Icon";

const AddToService = ({ onClick, song }) => {
  const serviceContext = useContext(ServiceContext);
  return (
    <div className="d-block text-right clickable">
      <Icon name={renderClasses(song, serviceContext)} onClick={onClick} />
    </div>
  );
};

function renderClasses(song, serviceContext) {
  const service = serviceContext.currentService;
  const songFound = service.find((s) => s._id === song._id);
  const classes = songFound ? "checkmark-circle" : "add-circle-outline";

  return classes;
}

export default AddToService;
