import Icon from './Icon';

const ViewServiceBtn = ({ onViewService, service }) => {
  return (
    <span className="clickable view-service-control">
      <Icon name="play-circle" onClick={() => onViewService(service)} />
    </span>
  );
};

export default ViewServiceBtn;
