const Footer = ({ marginBottom }) => {
  const margin = marginBottom ? marginBottom : 5;
  // footer-end: to detect the end of a page when scrolling
  return (
    <>
      <footer style={{ marginBottom: `${margin}rem` }}></footer>
      <span className="footer-end"></span>
    </>
  );
};

export default Footer;
