import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="container">
      <div className="not-found mt-5">
        <div className="text-center">
          <h2>Not Found</h2>
          <p>
            The service, song, or resource you were looking for was deleted or
            does not exist.
          </p>
          <Link to="/songs" className="">
            Go to Songs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
