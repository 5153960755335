import { ThreeDots } from 'svg-loaders-react';
import Icon from './Icon';
import './NoContent.css';

const NoContent = ({ loading, message, classes }) => {
  if (loading && loading === true)
    return (
      <div className={`no-content-loader ${renderClasses(classes)}`}>
        <ThreeDots width="2em" />
      </div>
    );
  else if (message)
    return (
      <div className={renderClasses(classes)}>
        <p>
          <Icon name="information-circle" />
          <span> {message}</span>
        </p>
      </div>
    );
  else return null;
};

function renderClasses(classes) {
  let defaultClass = 'no-content';
  if (classes) return (defaultClass += ` ${classes}`);
  return defaultClass;
}

export default NoContent;
