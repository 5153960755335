const BackButton = ({ handler }) => {
  return (
    <div>
      <button onClick={handler} className="btn btn-sm btn-light">
        &lt; Go Back
      </button>
    </div>
  );
};

export default BackButton;
