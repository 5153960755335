const renderKey = (option) => {
  if (option._id) return option._id;

  return option;
};

const Select = ({ name, label, value, options, error, onChange }) => {
  return (
    <>
      <div className="form-group">
        <select
          name={name}
          value={value}
          onChange={(e) => onChange(e)}
          className={`custom-select ${name}`}
        >
          {label && <option value="">{label}</option>}
          {options.map((option) => (
            <option key={renderKey(option)} value={renderKey(option)}>
              {option.name || option}
            </option>
          ))}
        </select>
        {error && <small className="form-text text-danger">{error}</small>}
      </div>
    </>
  );
};

export default Select;
