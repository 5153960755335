import { Component } from 'react';
import { toast } from 'react-toastify';
import ToggleSwitch from '../../components/common/ToggleSwitch';
import { getTeam, allowJoining } from '../../services/teamService';
import auth from '../../services/authService';
import theme from '../../utils/theme';
import { setDocTitle } from '../../utils/utilities';
import './Settings.css';

class Settings extends Component {
  state = { darkTheme: false, team: { allowJoining: false } };

  componentDidMount() {
    this.setLocalTeamAndUser();
    this.setThemeToState();
    setDocTitle('Settings');
  }

  setLocalTeamAndUser = async () => {
    const user = auth.getCurrentUser();
    const { data: team } = await getTeam(user.teamId);
    this.setState({ team, user });
  };

  setThemeToState = () => {
    const darkTheme = theme.getTheme('darkTheme');
    if (darkTheme === 'true') {
      this.setState({ darkTheme: true });
    }
  };

  handleDarkThemeToggle = () => {
    const { darkTheme } = this.state;
    if (!darkTheme) theme.setDarkTheme();
    else theme.removeDarkTheme();

    this.setState({ darkTheme: !darkTheme });
  };

  handleAllowJoining = async () => {
    const team = { ...this.state.team };
    team.allowJoining = !team.allowJoining;
    this.setState({ team });
    try {
      await allowJoining(team.allowJoining);
    } catch (ex) {
      if (ex.response) {
        toast.error('Unable to save this setting. Please try again later.');
      }
    }
  };

  render() {
    const { team, user } = this.state;
    return (
      <div className="container">
        <div className="settings">
          <h3 className="mb-4">Settings</h3>
          <ToggleSwitch
            name="darkTheme"
            value={this.state.darkTheme}
            checked={this.state.darkTheme}
            label="Dark Theme"
            onChange={this.handleDarkThemeToggle}
          />
          {user && user.isAdmin && (
            <ToggleSwitch
              name="allowJoining"
              value={team.allowJoining}
              checked={team.allowJoining}
              label="Allow New Members"
              onChange={this.handleAllowJoining}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Settings;
