import { useState, useEffect, useRef } from 'react';
import AutoScrollBtn from '../AutoScrollBtn/AutoScrollBtn';
import { settingKeys, getSetting } from '../../../../../utils/settings';
import create from 'zustand';

export const useAutoScrollPluginStore = create((set) => ({
  scrolling: false,
  setScrolling: (value) => set({ scrolling: value }),
  scrollingSpeed: 0,
  setScrollingSpeed: (speed) => set({ scrollingSpeed: speed }),
  selectedBtn: 0,
  setSelectedBtn: (value) => set({ selectedBtn: value }),
  scrollTimerId: null,
  setScrollTimerId: (id) => set({ scrollTimerId: id }),
}));

const AutoScrollPlugin = ({ mirror }) => {
  const { documentElement: doc } = document;
  const stopScrollingKey = settingKeys.stopScrollingKey;
  let localScrollingSpeed = useRef(0);
  const [bodyClickTimerId, setBodyClickTimerId] = useState('');

  const scrolling = useAutoScrollPluginStore((state) => state.scrolling);
  const setScrolling = useAutoScrollPluginStore((state) => state.setScrolling);

  const scrollingSpeed = useAutoScrollPluginStore((state) => state.scrollingSpeed);
  const setScrollingSpeed = useAutoScrollPluginStore((state) => state.setScrollingSpeed);

  const selectedBtn = useAutoScrollPluginStore((state) => state.selectedBtn);
  const setSelectedBtn = useAutoScrollPluginStore((state) => state.setSelectedBtn);

  const scrollTimerId = useAutoScrollPluginStore((state) => state.scrollTimerId);
  const setScrollTimerId = useAutoScrollPluginStore((state) => state.setScrollTimerId);

  useEffect(() => {
    return () => {
      if (scrolling) {
        clearInterval(scrollTimerId);
      } else {
        clearInterval(bodyClickTimerId);
      }
    };
  });

  function scrollAt(speed) {
    setSelectedBtn(speed);
    // stop scrolling if the same speed is passed twice
    if (scrolling && speed === scrollingSpeed) {
      stopAutoScroll();
      return;
    }
    // start scrolling
    setScrollTimerId(setInterval(scroller, speed));
    setScrolling(true);
    setScrollingSpeed(speed);
    localScrollingSpeed.current = speed;
  }

  function scroller() {
    checkForViewBodyClick();
    doc.scrollTop += 1;

    const height = doc.scrollHeight - doc.clientHeight;
    const winScroll = doc.scrollTop;
    // stop auto scrolling at the end of the song

    if (Math.ceil(winScroll) >= height || isInViewport('.footer-end')) {
      stopAutoScroll();
    }
  }

  function stopAutoScroll() {
    clearInterval(scrollTimerId);
    setScrolling(false);
    setSelectedBtn(null);
  }

  function checkForViewBodyClick() {
    const stopScrolling = getSetting(stopScrollingKey);

    if (stopScrolling) {
      stopAutoScroll();
      // check for ViewBody click while not scrolling
      setBodyClickTimerId(
        setInterval(() => {
          const stopScrolling = getSetting(stopScrollingKey);
          if (!stopScrolling) {
            scrollAt(localScrollingSpeed.current);
            clearInterval(bodyClickTimerId);
          }
        }, 500)
      );
    }
  }

  function isInViewport(el) {
    const rect = document.querySelector(el).getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  return (
    <div className={`song-plugin auto-scroll-plugin ${mirror && 'mirror horizontal-menu'}`}>
      {!mirror && <span className="plugin__title">Auto Scroll</span>}
      <AutoScrollBtn scrollAt={scrollAt} speed={150} label="1" selected={selectedBtn} />
      <AutoScrollBtn scrollAt={scrollAt} speed={130} label="2" selected={selectedBtn} />
      <AutoScrollBtn scrollAt={scrollAt} speed={110} label="3" selected={selectedBtn} />
      <AutoScrollBtn scrollAt={scrollAt} speed={90} label="4" selected={selectedBtn} />
      <AutoScrollBtn scrollAt={scrollAt} speed={70} label="5" selected={selectedBtn} />
      <AutoScrollBtn scrollAt={scrollAt} speed={60} label="6" selected={selectedBtn} />
      <AutoScrollBtn scrollAt={scrollAt} speed={50} label="7" selected={selectedBtn} />
    </div>
  );
};

export default AutoScrollPlugin;
