const SongCount = ({ service, songPosition }) => {
  const renderSongCount = () => {
    if (!service) return '';

    return `${songPosition}/${service.songsIds.length}`;
  };

  return <span className="song-count">{` ${renderSongCount()} songs`}</span>;
};

export default SongCount;
