const Members = ({ team }) => {
  return (
    <div className="team-members">
      <p className="team-members__count">
        {team.users &&
          `${team.users.length} ${
            team.users.length === 1 ? "member" : "members"
          }`}
      </p>
      {team.users &&
        team.users.map((user) => (
          <span key={user._id} className="team-members__member">
            <span className="team-members__member-text">
              {formatName(user.name)}
            </span>
          </span>
        ))}
    </div>
  );
};

const formatName = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export default Members;
