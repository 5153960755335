import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import auth from '../../services/authService';
import { setDocTitle } from '../../utils/utilities';
import Icon from '../../components/common/Icon';
import { getTeam } from '../../services/teamService';
import TeamMembers from './components/TeamMembers';
import TeamInvitation from './components/TeamInvitation';
import './Team.css';

const MyTeam = () => {
  const [team, setTeam] = useState({});
  const [user, setUser] = useState({});
  const [inviteLink, setInviteLink] = useState('');

  useEffect(() => {
    setDocTitle('My Team');
    // Get current user
    setUser(auth.getCurrentUser());

    // Get user's team
    const fetchTeam = async () => {
      if (user.teamId) {
        const { data: team } = await getTeam(user.teamId);
        setTeam(team);
      }
    };
    fetchTeam();

    setInviteLink(`https://${window.location.host}/signup/${team._id}`);
  }, [user.teamId, team._id]);

  const copyToClipboard = (el) => {
    const selection = window.getSelection();
    const range = document.createRange();

    try {
      range.selectNodeContents(el.current);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      toast.info('Copied!', { autoClose: 3000, hideProgressBar: true });
    } catch (ex) {
      toast.error('There was an error while trying to copy.');
    }
  };

  return (
    <div className="container">
      <div className="my-team">
        <Icon name="flag" className="my-team__flag" />
        <h2>{team.name}</h2>
        <TeamMembers team={team} />
        {user && user.isAdmin && <TeamInvitation team={team} inviteLink={inviteLink} onCopyLink={copyToClipboard} />}
      </div>
    </div>
  );
};

export default MyTeam;
