import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import { getTeam, createTeam } from '../../../services/teamService';
import userService from '../../../services/userService';
import auth from '../../../services/authService';
import Form from '../../common/Form';
import FormHeader from '../../common/FormHeader';
import TeamOptions from './components/TeamOptions';
import JoinTeam from './components/JoinTeam';
import CreateTeam from './components/CreateTeam';
import { setDocTitle } from '../../../utils/utilities';

class SignUp extends Form {
  state = {
    data: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    errors: {},
    team: { _id: '', name: '' },
    btnText: 'Continue',
    step: 1,
    callingApi: false,
  };

  schema = {
    firstName: Joi.string().min(2).max(50).required().label('First Name'),
    lastName: Joi.string().min(2).max(50).required().label('Last Name'),
    email: Joi.string().min(5).max(255).required().email().label('Email Address'),
    password: Joi.string().min(8).max(255).required().label('Password'),
    confirmPassword: Joi.any()
      .valid(Joi.ref('password'))
      .required()
      .options({ language: { any: { allowOnly: 'must match' } } })
      .label('Passwords'),
  };

  async componentDidMount() {
    await this.populateTeam();
    setDocTitle('Sign Up');
  }

  populateTeam = async (id) => {
    const teamId = this.props.match.params.teamId || id;

    if (teamId) {
      try {
        this.setState({ callingApi: true });

        const team = { ...this.state.team };
        const { data: validTeam } = await getTeam(teamId);

        team._id = validTeam._id;
        team.name = validTeam.name;

        this.setState({
          team,
          btnText: `Join ${team.name}`,
          callingApi: false,
        });
      } catch (ex) {
        toast.error('Invalid Team ID.');
        this.setState({ callingApi: false });
      }
    }
  };

  handleShowJoinTeam = () => {
    this.setState({ step: 3 });
  };

  handleShowCreateTeam = () => {
    this.setState({ step: 4 });
  };

  handleShowTeamOptions = () => {
    this.setState({ step: 2 });
  };

  handleShowSignUpForm = () => {
    this.setState({ step: 1 });
  };

  handleJoinTeam = (teamId) => {
    this.setState({ step: 1 });
    this.populateTeam(teamId);
  };

  handleCreateTeam = async (teamName) => {
    // Uncomment this to enable creating new teams
    alert('Migrupo is currently not accepting new teams.\nWe apologize for any inconvenience this may cause.');
    return;

    try {
      this.setState({ callingApi: true });
      const { data: newTeam } = await createTeam(teamName);
      const team = { ...this.state.team };

      team._id = newTeam._id;
      team.name = newTeam.name;
      team.isAdmin = true;

      this.setState({ team });

      this.doSubmit();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error('Something failed while creating a team.');
      }
      this.setState({ callingApi: false });
    }
  };

  doSubmit = async () => {
    const { data, team } = this.state;

    // Check email availability
    try {
      this.setState({ callingApi: true });

      const { data: availableEmail } = await userService.available(data.email);

      if (!availableEmail) {
        const errors = { ...this.state.errors };
        errors.email = 'This email has already been used.';
        this.setState({ errors, callingApi: false });
        return;
      }
      this.setState({ callingApi: false });
    } catch (ex) {
      this.setState({ callingApi: false });
      return;
    }

    if (team._id) {
      const user = { ...data };
      user.teamId = team._id;

      if (team.isAdmin) user.isAdmin = team.isAdmin;

      try {
        this.setState({ callingApi: true });

        const { headers } = await userService.register(user);

        auth.signinWithJwt(headers['x-auth-token']);

        window.location = '/songs';
      } catch (ex) {
        if (ex.response && ex.response.status === 403) {
          toast.error(`${team.name} is not accepting new members.`, {
            autoClose: 10000,
          });
        }

        this.setState({ callingApi: false });
      }
    } else {
      // If there isn't a team id take the user to join or create a team
      this.handleShowTeamOptions();
    }
  };

  render() {
    // If there is an existing user, redirect to home
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    const { btnText, step, data, callingApi } = this.state;

    switch (step) {
      case 1:
        return (
          <div className="vertical-center">
            <form onSubmit={this.handleSubmit} className="form-signup">
              <FormHeader title="Create an account" />
              <div className="no-new-teams">
                ⚠️ Migrupo is currently not accepting new teams. We apologize for any inconvenience this may cause.
              </div>
              <div className="form-row">
                <div className="col">{this.renderInput('firstName', 'First Name', 'text')}</div>
                <div className="col">{this.renderInput('lastName', 'Last Name', 'text')}</div>
              </div>
              {this.renderInput('email', 'Email address', 'email')}
              {this.renderInput('password', 'Password', 'password')}
              {this.renderInput('confirmPassword', 'Confirm Password', 'password')}
              {this.renderButton(btnText, callingApi)}

              <div className="text-center">
                <span>Already have an account? </span>
                <Link to="/signin">Sign In</Link>
              </div>
            </form>
          </div>
        );
      case 2:
        return (
          <div className="vertical-center">
            <TeamOptions
              firstName={data.firstName}
              onShowJoinTeam={this.handleShowJoinTeam}
              onShowCreateTeam={this.handleShowCreateTeam}
              onGoBack={this.handleShowSignUpForm}
            />
          </div>
        );
      case 3:
        return (
          <div className="vertical-center">
            <JoinTeam onJoinTeam={this.handleJoinTeam} onGoBack={this.handleShowTeamOptions} />
          </div>
        );
      case 4:
        return (
          <div className="vertical-center">
            <CreateTeam
              onCreateTeam={this.handleCreateTeam}
              onGoBack={this.handleShowTeamOptions}
              callingApi={callingApi}
            />
          </div>
        );

      default:
    }
  }
}

export default SignUp;
