import { useEffect } from 'react';
import Icon from '../../../../components/common/Icon';
import AutoScrollPlugin from '../plugins/AutoScrollPlugin';
import { useAutoScrollPluginStore } from '../plugins/AutoScrollPlugin/AutoScrollPlugin';
import { settingKeys, getSetting } from '../../../../utils/settings';

const ViewServiceBar = ({ onPrevious, onNext, firstSong, lastSong, service }) => {
  const renderPreviousClass = (firstSong) => (firstSong ? 'no-action' : 'clickable');
  const renderNextClass = (lastSong) => (lastSong ? 'no-action' : 'clickable');

  const scrolling = useAutoScrollPluginStore((state) => state.scrolling);
  const stopScrolling = getSetting(settingKeys.stopScrollingKey);

  useEffect(() => {
    // detect when the bar sticks
    const el = document.querySelector('.view__service-bar');
    const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('pinned', e.intersectionRatio < 1), {
      threshold: [1],
    });

    observer.observe(el);
  });

  const scrollStatus = () => {
    return scrolling || stopScrolling;
  };

  return (
    <div className="view__service-bar">
      {service && (
        <>
          <span className={`view__service-bar__title ${scrollStatus() && 'scrolling'}`}>
            <Icon className="view__service-bar__icon" name="calendar-sharp" />
            <span className="view__service-bar__text">{service.title}</span>
          </span>
          {scrollStatus() && <AutoScrollPlugin mirror={true} />}
        </>
      )}
      <span className="view__service-bar__action-icons">
        <span className={renderPreviousClass(firstSong)}>
          <Icon name="arrow-back-circle" onClick={onPrevious} />
        </span>
        <span className={renderNextClass(lastSong)}>
          <Icon name="arrow-forward-circle" onClick={onNext} />
        </span>
      </span>
    </div>
  );
};

export default ViewServiceBar;
