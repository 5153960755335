import { Component } from "react";
import Joi from "joi-browser";
import Input from "./Input";
import InputNumber from "./InputNumber";
import Select from "./Select";
import Textarea from "./Textarea";
import Button from "./Button";

class Form extends Component {
  state = { data: {}, errors: {} };

  validatePart = (part) => {
    const partSchema = {
      currentLyrics: Joi.string().required().label("Lyrics and Chords"),
      partNumber: Joi.number().required().min(1).max(99).label("Number"),
    };

    const { error } = Joi.validate(part, partSchema, { abortEarly: false });
    return this.getErrors(error);
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const { data } = this.state;
    const { error } = Joi.validate(data, this.schema, { abortEarly: false });
    return this.getErrors(error);
  };

  getErrors = (error) => {
    if (!error) return null;
    // loop through errors
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  handleChange = ({ currentTarget: input }) => {
    // validate
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    // confirm password
    if (this.state.data.password === input.value) {
      delete errors.confirmPassword;
    }
    this.setState({ errors });
    // set the value for the input every time it changes
    this.setInputToState(input);
  };

  setInputToState = (input) => {
    let inputElement = input;
    if ("currentTarget" in input) {
      inputElement = input.currentTarget;
    }

    const data = { ...this.state.data };
    data[inputElement.name] = inputElement.value;
    this.setState({ data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  renderButton(label, loading, className) {
    return (
      <Button
        label={label}
        disabled={this.validate()}
        loading={loading}
        className={className}
      />
    );
  }

  renderInput(name, label, type) {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        value={data[name]}
        label={label}
        type={type}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }

  renderInputNumber(name, handler) {
    const { data, errors } = this.state;
    return (
      <InputNumber
        name={name}
        value={data[name]}
        error={errors[name]}
        onChange={handler || this.handleChange}
      />
    );
  }

  renderSelect(name, label, options, handler) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        label={label}
        value={data[name]}
        options={options}
        error={errors[name]}
        onChange={handler || this.handleChange}
      />
    );
  }

  renderTextarea(name, handler) {
    const { data, errors } = this.state;
    return (
      <Textarea
        name={name}
        id={name}
        value={data[name]}
        error={errors[name]}
        onChange={handler || this.handleChange}
      />
    );
  }
}

export default Form;
