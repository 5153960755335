import Joi from 'joi-browser';
import FormHeader from '../../../../common/FormHeader';
import Form from '../../../../common/Form';
import BackButton from '../BackButton';

class JoinTeam extends Form {
  state = { data: { teamId: '' }, errors: {} };

  schema = {
    teamId: Joi.string().required().min(24).label('Team ID'),
  };

  doSubmit = () => {
    this.props.onJoinTeam(this.state.data.teamId);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="form-signup">
        <BackButton handler={this.props.onGoBack} />
        <FormHeader title="Enter team ID" />
        {this.renderInput('teamId', 'Team ID', 'text')}
        {this.renderButton('Join Team')}
      </form>
    );
  }
}

export default JoinTeam;
